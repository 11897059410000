import styled, {} from '../../../styled-components';

export const AspectRatioWrapper = styled.div<{ width: number, height: number}>`
    position: relative;
    padding-bottom: ${props => (props.height || 9) / (props.width || 16) * 100}%
    height: 0;
    overflow: hidden;


    & > * {
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
  }
`;
