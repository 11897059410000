import styled, { css } from '../../../styled-components';
import { media, pixelToRem } from '../../../utilities';
import {
    BaseAnchorStyles,
    Heading2Styles,
    Heading3Styles,
    Heading4Styles
} from '..';
import { ListStyles } from '../List/List.component';
import { BlockquoteStyles } from '../Blockquote/Blockquote.component';
import { GuthenHeading2Styles } from '../Heading/GuthenHeading';

export const BaseProseStyles = css`
    ${({ theme }) => css`
        h1,
        h2,
        h3,
        h4,
        h5 {
            line-height: 1.4;
            font-weight: 500;
            margin: 0;
            margin-top: ${pixelToRem(32)};
            clear: left; /* make sure headings don't float against blockquotes */
            font-family: ${theme.fontNew.primary.type};

            ${media.m`
                margin-top: ${pixelToRem(42)};
            `}
        }

        h1 {
            ${Heading2Styles}
        }

        h2 {
            ${Heading3Styles}
        }

        h3 {
            ${Heading4Styles}
        }

        strong {
            font-weight: 500;
        }

        a {
            ${BaseAnchorStyles}

            &:hover {
                text-decoration-color: ${theme.colors.pink.color};
            }
        }

        ul {
            ${ListStyles}

            & > li:before {
                color: ${theme.colors.pink.color};
            }
        }

        blockquote:not(.pull-quote):not(.twitter-tweet) {
            ${BlockquoteStyles}
        }

        .guthen-body {
            font-family: ${theme.fontNew.secondary.type};
            font-size: ${theme.fontNew.secondary.size.level4.size};
            line-height: ${theme.fontNew.primary.size.level3.lineHeight};
            font-weight: normal;
            display: inline-block;
        }

        .guthen-title {
            ${GuthenHeading2Styles}
        }

        hr {
            position: relative;
            margin: ${theme.fontNew.primary.size.level5.size} auto;
            display: block;
            height: 2px;
            border: 0;
            background-color: ${theme.colors.pink.color};
            width: 25%;

            ${media.m`
                margin: 2.5rem auto;
            `}
        }

        .indent {
            padding-left: ${pixelToRem(20)};
            border-left: 2px solid ${theme.colors.pink.color};
        }

        table {
            width: 100%;
            border-collapse: collapse;
            border-spacing: 0;

            th,
            td {
                padding: 0.5rem;
                text-align: left;
            }

            .break-word {
                word-break: break-all;
            }

            th {
                background-color: ${theme.colors.deepBlue.color};
                color: white;
                font-weight: 500;
                border: solid 1px ${theme.colors.deepBlue.color};
            }

            td {
                border: solid 1px ${theme.colors.deepBlue.color};
            }
        }
    `}
`;

export const Prose = styled.div`
    ${BaseProseStyles}

    ${({ theme }) => css`
        margin-bottom: ${theme.fontNew.primary.size.level3.size};

        ${media.m`
            margin-bottom: ${theme.fontNew.primary.size.level3.size};
        `}
    `}
`;
