import { Constrain, Stat, Distribute, QuestionSubHeader, GuthenHeadingLevel2, ConstrainPullOut } from "../../../../src/components/ui";
import Testimonial from "../../../../src/components/Testimonial/Testimonial.component";
import elfhIpad from "../../../../data/case-studies/nhs-electronic-fetal-monitoring/elfh-ipad.jpg";
import * as React from 'react';
export default {
  Constrain,
  Stat,
  Distribute,
  QuestionSubHeader,
  GuthenHeadingLevel2,
  ConstrainPullOut,
  Testimonial,
  elfhIpad,
  React
};