import styled from '../../../styled-components';
import { media } from '../../../utilities';

export const Distribute = styled.div`
    ${media.l`
            display: flex;
            justify-content: space-between;
            align-items: flex-start;


            & > * {
                flex-basis: 50%;

                &:first-child {
                    margin-right: 2rem;
                }
            }
        `}
`;
