import styled from '../../../styled-components';
import { media } from '../../../utilities';

export const Landmark = styled.div`
    margin-bottom: 3rem;

    ${media.l`
        margin-bottom: 9rem;
    `}
`;
