import { keyframes } from '../styled-components';
import theme from '../theme';

export const fadeIn = keyframes`
    from {
        opacity: 0;
    }

    to {
        opacity: 1;
    }
`;

export const fadeUp = keyframes`
    from {
        opacity: 0;
        transform: translateY(100%) scale(.9);
    }

    to {
        opacity: 1;
        transform: translateY(0%) scale(1);;
    }
`;

export const colourCycle = keyframes`

    0% {
        fill: ${theme.colors.green.color};
    }

    33% {
        fill: ${theme.colors.green.color};
    }

    34% {
        fill: ${theme.colors.orange.color};
    }

    66% {
        fill: ${theme.colors.orange.color};
    }

    67% {
        fill: ${theme.colors.pink.color};
    }

    100% {
        fill: ${theme.colors.pink.color};
    }
`;
