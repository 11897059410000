import styled, { css } from '../../../styled-components';
import { media, pixelToRem } from '../../../utilities';

export const ListStyles = css`
    margin-bottom: 2rem;
    padding-left: 1.8rem;
    position: relative;

    ${media.m`
        margin-bottom: 2rem;
    `}

    ${media.l`
        margin-bottom: 3rem;
    `}

    & > li {
        list-style: none;
        margin-bottom: 1rem;

        &:before {
            content: '•';
            position: absolute;
            left: ${pixelToRem(4)};
            transform: translateX(-50%);
            color: ${({ theme }) => theme.colors.deepBlue.color};
        }
    }
`;

export const List = styled.ul<{ color?: wmw.ColorOptions }>`
    ${ListStyles}

    & > li:before {
        color: ${({ theme, color }) => theme.colors[color || 'deepBlue'].color};
    }
`;
