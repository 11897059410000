import styled, { css } from '../../../styled-components';
import { media } from '../../../utilities';

export const GuthenHeading2Styles = css`
    font-family: ${({ theme }) => theme.fontNew.secondary.type};
    font-size: ${({ theme }) => theme.fontNew.secondary.size.level4.size};
    line-height: ${({ theme }) => theme.fontNew.primary.size.level3.lineHeight};
    font-weight: normal;

    margin-top: ${({ theme }) => theme.fontNew.secondary.size.level4.size};
    margin-bottom: ${({ theme }) => theme.fontNew.secondary.size.level4.size};

    ${media.xl`
        font-size: ${({ theme }) => theme.fontNew.secondary.size.level2.size};
        line-height: ${({ theme }) =>
            theme.fontNew.primary.size.level1.lineHeight};
    `}
`;

export const GuthenHeading3Styles = css`
    font-family: ${({ theme }) => theme.fontNew.secondary.type};
    font-size: ${({ theme }) => theme.fontNew.secondary.size.level5.size};
    line-height: ${({ theme }) => theme.fontNew.primary.size.level4.lineHeight};
    font-weight: normal;

    margin-top: ${({ theme }) => theme.fontNew.secondary.size.level5.size};
    margin-bottom: ${({ theme }) => theme.fontNew.secondary.size.level5.size};

    ${media.xl`
        font-size: ${({ theme }) => theme.fontNew.secondary.size.level3.size};
        line-height: ${({ theme }) =>
            theme.fontNew.primary.size.level1.lineHeight};
    `}
`;

export const GuthenHeading4Styles = css`
    font-family: ${({ theme }) => theme.fontNew.secondary.type};
    font-size: ${({ theme }) => theme.fontNew.secondary.size.level5.size};
    line-height: ${({ theme }) => theme.fontNew.primary.size.level5.lineHeight};
    font-weight: normal;

    margin-top: 1.5rem;
    margin-bottom: 1.5rem;

    ${media.xl`
        font-size: ${({ theme }) => theme.fontNew.secondary.size.level4.size};
        line-height: ${({ theme }) =>
            theme.fontNew.primary.size.level1.lineHeight};
    `}
`;

export const GuthenHeadingLevel2 = styled.h1`
    ${GuthenHeading2Styles};
`;

export const GuthenHeadingLevel3 = styled.h3`
    ${GuthenHeading3Styles};
`;

export const GuthenHeadingLevel4 = styled.h4`
    ${GuthenHeading4Styles};
`;
