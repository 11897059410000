import React from 'react';
import { StylelessLink } from '..';
import styled, {css} from '../../../styled-components';
import { media, pixelToRem } from '../../../utilities';
import arrowSvgOrange from '../../../images/arrow--orange.svg';
import arrowSvg from '../../../images/arrow.svg';

interface ILinkArrowProps {
    to: string;
    color?: wmw.ColorOptions;
    arrowColor?: wmw.ColorOptions;
    inline?: boolean;
    keyline?: boolean;
}

// we should probably use something like https://www.gatsbyjs.org/packages/gatsby-plugin-react-svg/ if we need to do this more often.
export const ArrowSvg: React.FC = () => (
    <svg width="27" height="22" xmlns="http://www.w3.org/2000/svg">
        <g fillRule="evenodd">
            <path d="M12.7 3.5L15.5.6l10.6 10.6-2.8 2.9z" />
            <path d="M22.5 9.1l2.8 2.9-9.9 9.9-2.8-2.9z" />
            <path d="M0 9.2h21v4H0z" />
        </g>
    </svg>
);

export const LinkText = styled.span<{ underlineColor: wmw.ColorOptions }>`
    position: relative;

    &:after {
        content: '';
        position: absolute;
        top: 100%;
        left: 0;
        right: 0;
        height: 2px;
        background-color: ${({ theme, underlineColor }) =>
            theme.colors[underlineColor].color};
    }
`;

const UnstyledArrowLink = styled(StylelessLink)<ILinkArrowProps>`
    display: ${({ inline }) => (inline ? 'inline' : 'flex')};
    color: ${({ theme, color }) => theme.colors[color].color};
    align-items: center;
    font-weight: 500;

    ${({ keyline, theme, arrowColor }) => (keyline && css`
        border-left: 2px solid ${theme.colors[arrowColor].color};
        padding-left: 1.5rem;
    `)};

    svg {
        fill: ${({ theme, arrowColor }) => theme.colors[arrowColor].color};
        flex-shrink: 0;
        margin-left: ${pixelToRem(10)};
        transition: transform 300ms;
        vertical-align: middle;
    }

    &:hover svg {
        transform: translateX(${pixelToRem(10)});
    }

    .nowrap {
        display: inline-flex;
        align-items: center;
    }
`;

function renderChildren(props: { children: React.ReactNode; inline: boolean }) {
    if (typeof props.children === 'string' && props.inline) {
        const titleWords = props.children.split(' ');
        const lastWord = titleWords.pop();

        return (
            <>
                {titleWords.join(' ')}{' '}
                <span className="nowrap">
                    {lastWord}
                    <ArrowSvg />
                </span>
            </>
        );
    }
    return (
        <>
            <span style={{ whiteSpace: 'nowrap' }}>
                <span style={{ whiteSpace: 'normal' }}>{props.children}</span>
                <span>
                    &nbsp;
                    <ArrowSvg />
                </span>
            </span>
        </>
    );
}

export const LinkArrow: React.FC<ILinkArrowProps> = ({
    to,
    children,
    color = 'orange',
    arrowColor = 'orange',
    inline = false,
    keyline = false
}) => (
    <UnstyledArrowLink
        color={color}
        arrowColor={arrowColor}
        to={to}
        inline={inline}
        keyline={keyline}
    >
        {renderChildren({ children, inline })}
    </UnstyledArrowLink>
);
