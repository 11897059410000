// prefer default export if available
const preferDefault = m => (m && m.default) || m

exports.components = {
  "component---node-modules-gatsby-plugin-offline-app-shell-js": () => import("./../../../node_modules/gatsby-plugin-offline/app-shell.js" /* webpackChunkName: "component---node-modules-gatsby-plugin-offline-app-shell-js" */),
  "component---src-pages-404-tsx": () => import("./../../../src/pages/404.tsx" /* webpackChunkName: "component---src-pages-404-tsx" */),
  "component---src-pages-code-of-ethics-tsx": () => import("./../../../src/pages/code-of-ethics.tsx" /* webpackChunkName: "component---src-pages-code-of-ethics-tsx" */),
  "component---src-pages-cookies-tsx": () => import("./../../../src/pages/cookies.tsx" /* webpackChunkName: "component---src-pages-cookies-tsx" */),
  "component---src-pages-entrepreneur-with-an-idea-for-innovative-web-or-mobile-app-index-tsx": () => import("./../../../src/pages/entrepreneur-with-an-idea-for-innovative-web-or-mobile-app/index.tsx" /* webpackChunkName: "component---src-pages-entrepreneur-with-an-idea-for-innovative-web-or-mobile-app-index-tsx" */),
  "component---src-pages-entrepreneur-with-an-idea-for-innovative-web-or-mobile-app-thanks-tsx": () => import("./../../../src/pages/entrepreneur-with-an-idea-for-innovative-web-or-mobile-app/thanks.tsx" /* webpackChunkName: "component---src-pages-entrepreneur-with-an-idea-for-innovative-web-or-mobile-app-thanks-tsx" */),
  "component---src-pages-get-in-touch-tsx": () => import("./../../../src/pages/get-in-touch.tsx" /* webpackChunkName: "component---src-pages-get-in-touch-tsx" */),
  "component---src-pages-helping-organisations-fill-a-gap-in-a-team-index-tsx": () => import("./../../../src/pages/helping-organisations-fill-a-gap-in-a-team/index.tsx" /* webpackChunkName: "component---src-pages-helping-organisations-fill-a-gap-in-a-team-index-tsx" */),
  "component---src-pages-helping-organisations-fill-a-gap-in-a-team-thanks-tsx": () => import("./../../../src/pages/helping-organisations-fill-a-gap-in-a-team/thanks.tsx" /* webpackChunkName: "component---src-pages-helping-organisations-fill-a-gap-in-a-team-thanks-tsx" */),
  "component---src-pages-helping-organisations-improve-an-existing-process-index-tsx": () => import("./../../../src/pages/helping-organisations-improve-an-existing-process/index.tsx" /* webpackChunkName: "component---src-pages-helping-organisations-improve-an-existing-process-index-tsx" */),
  "component---src-pages-helping-organisations-improve-an-existing-process-thanks-tsx": () => import("./../../../src/pages/helping-organisations-improve-an-existing-process/thanks.tsx" /* webpackChunkName: "component---src-pages-helping-organisations-improve-an-existing-process-thanks-tsx" */),
  "component---src-pages-index-tsx": () => import("./../../../src/pages/index.tsx" /* webpackChunkName: "component---src-pages-index-tsx" */),
  "component---src-pages-making-an-ethical-impact-index-tsx": () => import("./../../../src/pages/making-an-ethical-impact/index.tsx" /* webpackChunkName: "component---src-pages-making-an-ethical-impact-index-tsx" */),
  "component---src-pages-privacy-tsx": () => import("./../../../src/pages/privacy.tsx" /* webpackChunkName: "component---src-pages-privacy-tsx" */),
  "component---src-pages-the-wave-machine-digital-product-clinic-index-tsx": () => import("./../../../src/pages/the-wave-machine-digital-product-clinic/index.tsx" /* webpackChunkName: "component---src-pages-the-wave-machine-digital-product-clinic-index-tsx" */),
  "component---src-pages-the-wave-machine-digital-product-clinic-thanks-tsx": () => import("./../../../src/pages/the-wave-machine-digital-product-clinic/thanks.tsx" /* webpackChunkName: "component---src-pages-the-wave-machine-digital-product-clinic-thanks-tsx" */),
  "component---src-pages-why-us-index-tsx": () => import("./../../../src/pages/why-us/index.tsx" /* webpackChunkName: "component---src-pages-why-us-index-tsx" */),
  "component---src-templates-article-tsx": () => import("./../../../src/templates/Article.tsx" /* webpackChunkName: "component---src-templates-article-tsx" */),
  "component---src-templates-case-study-tsx": () => import("./../../../src/templates/CaseStudy.tsx" /* webpackChunkName: "component---src-templates-case-study-tsx" */)
}

