import React, { FunctionComponent } from 'react';
import styled from '../../../styled-components';
import { media } from '../../../utilities';

export const Stat = styled.div`
    position: relative;
    padding-left: 2rem;
    margin-bottom: 3rem;
    font-size: ${({ theme }) => theme.fontNew.primary.size.level5.size};
    line-height: ${({ theme }) => theme.fontNew.primary.size.level5.lineHeight};

    ${media.xl`
        font-size: ${({ theme }) => theme.fontNew.primary.size.level4.size};
        line-height: ${({ theme }) =>
            theme.fontNew.primary.size.level4.lineHeight};
    `}

    /* &:last-child {
        margin-bottom: 6rem;
    } */

    &:before {
        content: '';
        position: absolute;
        width: 2px;
        top: 0;
        left: 0;
        bottom: 0;
        background-color: ${({ theme }) => theme.colors.orange.color};
    }

    span {
        display: block;

        &:first-child {
            font-size: ${({ theme }) => theme.fontNew.primary.size.level2.size};

            line-height: ${({ theme }) =>
                theme.fontNew.primary.size.level2.lineHeight};
            font-weight: 500;

            ${media.xl`
                font-size: ${({ theme }) =>
                    theme.fontNew.primary.size.level1.size};
                line-height: ${({ theme }) =>
                    theme.fontNew.primary.size.level1.lineHeight};
            `}
        }
    }
`;
