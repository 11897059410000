import { Constrain, Stat, Distribute, Landmark, QuestionSubHeader, GuthenHeadingLevel2, ConstrainPullOut } from "../../../../src/components/ui";
import Testimonial from "../../../../src/components/Testimonial/Testimonial.component";
import sharetraceScreenshots from "../../../../data/case-studies/sharetrace-covid-19-app/sharetrace-covid-19-screenshots.png";
import * as React from 'react';
export default {
  Constrain,
  Stat,
  Distribute,
  Landmark,
  QuestionSubHeader,
  GuthenHeadingLevel2,
  ConstrainPullOut,
  Testimonial,
  sharetraceScreenshots,
  React
};