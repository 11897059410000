import { Constrain, Stat, Distribute, Landmark, QuestionSubHeader, GuthenHeadingLevel2, ConstrainPullOut, AspectRatioWrapper } from "../../../../src/components/ui";
import Testimonial from "../../../../src/components/Testimonial/Testimonial.component";
import bransonTweet from "../../../../data/case-studies/vote-for-policies/richard-branson-vfp-tweet.png";
import cravenTweet from "../../../../data/case-studies/vote-for-policies/alex-craven-vfp-tweet.png";
import * as React from 'react';
export default {
  Constrain,
  Stat,
  Distribute,
  Landmark,
  QuestionSubHeader,
  GuthenHeadingLevel2,
  ConstrainPullOut,
  AspectRatioWrapper,
  Testimonial,
  bransonTweet,
  cravenTweet,
  React
};