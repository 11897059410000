import React from 'react';
import { StylelessLink } from '..';
import styled from '../../../styled-components';
import { media, pixelToRem } from '../../../utilities';
import arrowSvgOrange from '../../../images/arrow--orange.svg';
import arrowSvg from '../../../images/arrow.svg';

const UnstyledHeadingLink = styled(StylelessLink)`
    img {
        width: ${pixelToRem(25)};
        height: ${pixelToRem(25)};
        margin-left: ${pixelToRem(10)};
        vertical-align: middle;

        transition: transform 300ms;
    }

    &:hover img {
        transform: translateX(${pixelToRem(10)});
    }

    ${media.m`
        img {
            width: ${pixelToRem(30)};
            height: ${pixelToRem(30)};
            margin-left: ${pixelToRem(15)};
        }
    `}
`;

interface IHeadingLinkProps {
    linkText: string;
    to: string;
    color?: 'orange' | 'electricBlue' | 'green';
}

const NoWrap = styled.span`
    white-space: nowrap;
`;

function getLinkTextWithArrow(linkText: string, color: wmw.ColorOptions) {
    const linkTextArr = linkText.split(' ');
    const lastWord = linkTextArr[linkTextArr.length - 1];
    const allButLastWord = linkTextArr
        .slice(0, linkTextArr.length - 1)
        .join(' ');

    return (
        <>
            {allButLastWord + ' '}
            <NoWrap>
                {lastWord}
                <img
                    src={color === 'orange' ? arrowSvgOrange : arrowSvg}
                    alt=""
                    role="presentation"
                />
            </NoWrap>
        </>
    );
}

export const HeadingLink: React.FC<IHeadingLinkProps> = ({
    linkText,
    to,
    color = 'orange'
}) => (
    <UnstyledHeadingLink to={to}>
        {getLinkTextWithArrow(linkText, color)}
    </UnstyledHeadingLink>
);
