import React from 'react';

import styled from '../../../styled-components';
import { media } from '../../../utilities';
import IconTick from '../../../images/icons/tick.svg';

export const CheckList: React.FunctionComponent<{ items: string[] }> = props => {
    const IconList = styled.ul`
        margin: 0;
        padding: 0;
        list-style: none;

        ${media.m`
            display: flex;
            flex-wrap: wrap;
        `}

        li {
            display: flex;
            align-items: center;
            padding: 0.25rem 0;
            padding-right: 3rem;
            font-weight: 500;
        }

        svg {
            width: 24px;
            height: 21px;
            margin-right: 0.5rem;

            & #tick {
                fill: ${({ theme }) => theme.colors.pink.color};
            }
        }
    `;

    return (
        <IconList>
            {props.items.map(item => (
                <li>
                    <IconTick /> {item}{' '}
                </li>
            ))}
        </IconList>
    );
};
