import * as React from 'react';
import styled, { css } from '../../../styled-components';
import { pixelToRem, media } from '../../../utilities';

interface IConstrainProps {
    children: any;
    type?: 'normal' | 'narrow' | 'wide';
    relative?: boolean;
    className?: string;
}

export const CONSTRAIN_SIZING = {
    narrow: 860,
    normal: 1056,
    wide: 1400
};

export const pullBackSmall = `-3rem`;
export const pullBackWide = `-6rem`;
export const pullBackToNormalConstrainFromNarrow = `-${(CONSTRAIN_SIZING.normal -
    CONSTRAIN_SIZING.narrow) /
    2}px`;
export const pullBackExtraWide = `-15.2rem`;
export const pullNarrowBackExtraWide = `-24rem`; // Should be 27rem but added 3rem padding back to this

export const pullbackMarginToNormalConstrainFromNarrow = css`
    margin-left: ${pullBackToNormalConstrainFromNarrow};
    margin-right: ${pullBackToNormalConstrainFromNarrow};
`;

export const pullbackMarginsSmall = css`
    margin-left: ${pullBackSmall};
    margin-right: ${pullBackSmall};
`;

export const pullbackMarginsWide = css`
    margin-left: ${pullBackWide};
    margin-right: ${pullBackWide};
`;

export const pullbackMarginsExtraWide = css`
    margin-left: ${pullBackExtraWide};
    margin-right: ${pullBackExtraWide};
`;

const UnstyledConstrain: React.SFC<IConstrainProps> = ({
    className,
    children
}) => <div className={className}>{children}</div>;

export const Constrain = styled(UnstyledConstrain)`
    width: 100%;
    max-width: ${({ type }) =>
        type
            ? pixelToRem(CONSTRAIN_SIZING[type])
            : pixelToRem(CONSTRAIN_SIZING.normal)};
    margin: 0 auto;
    position: ${({ relative }) => (relative ? 'relative' : 'auto')};

    padding: 0 3rem;

    ${media.m`
        padding: 0 6rem;
    `}

    ${media.l`
        padding:  0 10rem;
    `}

    ${media.xl`
        padding:  0;
    `}
`;
