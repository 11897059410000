import styled, { css } from '../../../styled-components';
import { media } from '../../../utilities';

export const Heading1Styles = css`
    font-size: ${({ theme }) => theme.fontNew.primary.size.level2.size};
    line-height: ${({ theme }) => theme.fontNew.primary.size.level2.lineHeight};
    margin-bottom: ${({ theme }) => theme.fontNew.primary.size.level2.size};
    font-weight: 500;

    ${media.xl`
        font-size: ${({ theme }) => theme.fontNew.primary.size.level1.size};
        line-height: ${({ theme }) =>
            theme.fontNew.primary.size.level1.lineHeight};
        margin-bottom: ${({ theme }) => theme.fontNew.primary.size.level1.size};
    `}
`;

export const Heading2Styles = css`
    font-size: ${({ theme }) => theme.fontNew.primary.size.level3.size};
    line-height: ${({ theme }) => theme.fontNew.primary.size.level3.lineHeight};
    margin-bottom: ${({ theme }) => theme.fontNew.primary.size.level3.size};
    font-weight: 500;

    ${media.xl`
        font-size: ${({ theme }) => theme.fontNew.primary.size.level2.size};
        line-height: ${({ theme }) =>
            theme.fontNew.primary.size.level2.lineHeight};
        margin-bottom: ${({ theme }) => theme.fontNew.primary.size.level2.size};
    `}
`;

export const Heading3Styles = css`
    font-size: ${({ theme }) => theme.fontNew.primary.size.level4.size};
    line-height: ${({ theme }) => theme.fontNew.primary.size.level4.lineHeight};
    margin-bottom: ${({ theme }) => theme.fontNew.primary.size.level4.size};
    font-weight: 500;

    ${media.xl`
        font-size: ${({ theme }) => theme.fontNew.primary.size.level3.size};
        line-height: ${({ theme }) =>
            theme.fontNew.primary.size.level3.lineHeight};
        margin-bottom: ${({ theme }) => theme.fontNew.primary.size.level3.size};
    `}
`;

export const Heading4Styles = css`
    font-size: ${({ theme }) => theme.fontNew.primary.size.level5.size};
    line-height: ${({ theme }) => theme.fontNew.primary.size.level5.lineHeight};
    margin-bottom: ${({ theme }) => theme.fontNew.primary.size.level5.size};
    font-weight: 500;

    ${media.xl`
        font-size: ${({ theme }) => theme.fontNew.primary.size.level4.size};
        line-height: ${({ theme }) =>
            theme.fontNew.primary.size.level4.lineHeight};
        margin-bottom: ${({ theme }) => theme.fontNew.primary.size.level4.size};
    `}
`;

export const HeadingLevel1 = styled.h1`
    ${Heading1Styles};
`;

export const HeadingLevel2 = styled.h2`
    ${Heading2Styles};
`;

export const HeadingLevel3 = styled.h3`
    ${Heading3Styles};
`;

export const HeadingLevel4 = styled.h4`
    ${Heading4Styles};
`;
