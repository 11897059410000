import React from 'react';
import styled from '../../../styled-components';
import { media } from '../../../utilities';
import andIllustration from '../../../images/and-illustration.svg';

const AndDividerStyle = styled.div`
    text-align: center;
    margin: 0 0 1.5rem 0;

    ${media.m`
    margin-top: 1.5rem;
`}

    ${media.l`
    margin: 2rem 0 1rem 0;
`}

img {
        width: 80px;
    }
`;

export const AndDivider: React.FunctionComponent = props => {
    return (
        <AndDividerStyle>
            <img src={andIllustration} alt="and" />
        </AndDividerStyle>
    );
};
