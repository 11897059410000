import { css } from '../styled-components';
import { ThemedCssFunction } from 'styled-components';
import { WMWTheme } from '../theme';

/* tslint:disable:object-literal-sort-keys */
export const breakpoints: any = {
    s: 576,
    m: 768,
    l: 1024,
    xl: 1280,
    xxl: 1600
};

// Iterate through the sizes and create a media template
export const media: {
    [key in keyof typeof breakpoints]: ThemedCssFunction<WMWTheme>
} = Object.keys(breakpoints as Array<keyof typeof breakpoints>).reduce(
    (acc: any, label: any) => {
        acc[label] = (first: any, ...args: any) => css`
            @media (min-width: ${breakpoints[label] / 16}em) {
                ${css(first, ...args)}
            }
        `;
        return acc;
    },
    {} as { [key in keyof typeof breakpoints]: ThemedCssFunction<WMWTheme> }
);

export const mediaMax: {
    [key in keyof typeof breakpoints]: ThemedCssFunction<WMWTheme>
} = Object.keys(breakpoints as Array<keyof typeof breakpoints>).reduce(
    (acc: any, label: any) => {
        acc[label] = (first: any, ...args: any) => css`
            @media (max-width: ${(breakpoints[label] - 1) / 16}em) {
                ${css(first, ...args)}
            }
        `;
        return acc;
    },
    {} as { [key in keyof typeof breakpoints]: ThemedCssFunction<WMWTheme> }
);
