import React from 'react';
import styled from '../../../styled-components';

interface ISwellProps {
    className?: string;
    id?: string;
}

const SwellSVG: React.SFC<ISwellProps> = ({ className, ...props }) => (
    <svg
        className={className}
        width="74px"
        height="96px"
        viewBox="0 0 74 96"
        version="1.1"
        preserveAspectRatio="none"
        xmlns="http://www.w3.org/2000/svg"
        xmlnsXlink="http://www.w3.org/1999/xlink"
        {...props}
    >
        <g id="Swell">
            <g
                id="Group-5"
                transform="translate(37.000000, 5.500000) scale(-1, 1) translate(-37.000000, -5.500000) "
            >
                <rect id="Rectangle" x="0" y="0" width="74" height="1" />
                <rect
                    id="Rectangle-Copy-27"
                    x="0"
                    y="5.5"
                    width="74"
                    height="1"
                />
                <rect
                    id="Rectangle-Copy-28"
                    x="0"
                    y="10.0833333"
                    width="74"
                    height="1"
                />
            </g>
            <g
                id="Group-6"
                transform="translate(37.000000, 33.500000) scale(-1, 1) translate(-37.000000, -33.500000) translate(0.000000, 27.000000)"
            >
                <rect
                    id="Rectangle-Copy-29"
                    x="0"
                    y="0"
                    width="74"
                    height="1.85714286"
                />
                <rect
                    id="Rectangle-Copy-30"
                    x="0"
                    y="5.57142857"
                    width="74"
                    height="1.85714286"
                />
                <rect
                    id="Rectangle-Copy-31"
                    x="0"
                    y="11.1428571"
                    width="74"
                    height="1.85714286"
                />
            </g>
            <g
                id="Group-7"
                transform="translate(37.000000, 61.500000) scale(-1, 1) translate(-37.000000, -61.500000) translate(0.000000, 54.000000)"
            >
                <rect
                    id="Rectangle-Copy-32"
                    x="0"
                    y="7.27595761e-12"
                    width="74"
                    height="2.8125"
                />
                <rect
                    id="Rectangle-Copy-33"
                    x="0"
                    y="5.625"
                    width="74"
                    height="2.8125"
                />
                <rect
                    id="Rectangle-Copy-34"
                    x="0"
                    y="12.1875"
                    width="74"
                    height="2.8125"
                />
            </g>
            <g
                id="Group-8"
                transform="translate(37.000000, 87.500000) scale(-1, 1) translate(-37.000000, -87.500000) translate(0.000000, 79.000000)"
            >
                <rect
                    id="Rectangle-Copy-35"
                    x="0"
                    y="-7.27595761e-12"
                    width="74"
                    height="3.77777778"
                />
                <rect
                    id="Rectangle-Copy-36"
                    x="0"
                    y="6.61111111"
                    width="74"
                    height="3.77777778"
                />
                <rect
                    id="Rectangle-Copy-37"
                    x="0"
                    y="13.2222222"
                    width="74"
                    height="3.77777778"
                />
            </g>
        </g>
    </svg>
);

export const Swell = styled(SwellSVG)`
    display: block;
    width: 100%;
    max-height: 101px;
`;
