interface AugmentedWindow extends Window {
    gtag: (
        type: 'event',
        event: string,
        data: { event_category: string; event_label: string; value?: string }
    ) => void;
}
/* tslint:disable */
let googleAnalyticsTrackEvent = (selection: string) => {};

if (typeof window !== 'undefined') {
    const augmentedWindow = (window as unknown) as AugmentedWindow;

    googleAnalyticsTrackEvent = (selection: string) => {
        augmentedWindow.gtag &&
            augmentedWindow.gtag('event', 'view_item', {
                event_category: 'Who can we help?',
                event_label: selection
            });
    };
}

export { googleAnalyticsTrackEvent };
