import { Constrain, Stat, Distribute, Landmark, QuestionSubHeader, GuthenHeadingLevel2, ConstrainPullOut, AspectRatioWrapper } from "../../../../src/components/ui";
import Testimonial from "../../../../src/components/Testimonial/Testimonial.component";
import andreasPhoto from "../../../../data/case-studies/pubal-saas-platform/andreas.jpg";
import * as React from 'react';
export default {
  Constrain,
  Stat,
  Distribute,
  Landmark,
  QuestionSubHeader,
  GuthenHeadingLevel2,
  ConstrainPullOut,
  AspectRatioWrapper,
  Testimonial,
  andreasPhoto,
  React
};