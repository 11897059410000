import { CONSTRAIN_SIZING } from '..';
import styled from '../../../styled-components';
import { media, pixelToRem } from '../../../utilities';

export const ConstrainPullOut = styled.div`
    ${media.l`
        margin: 4rem -${pixelToRem(
            (CONSTRAIN_SIZING.normal - CONSTRAIN_SIZING.narrow) / 2
        )};
    `}
`;
