import styled from '../../../styled-components';

export const StylelessButton = styled.button`
    appearance: none;
    border: 0;
    background: none;
    text-align: left;
    padding: 0;
    color: ${({ theme }) => theme.colors.deepBlue.color};
`;

export const Button = styled.button``;
