import React from 'react';
import styled, { css } from '../../../styled-components';
import { Link as GatsbyLink, GatsbyLinkProps } from 'gatsby';

// Since DOM elements <a> cannot receive activeClassName,
// destruct the prop here and pass it only to GatsbyLink
interface AdditionalLinkProps {
    highlightColor?: 'electricBlue' | 'pink';
    underlineColor?: wmw.ColorOptions;
    reverseColor?: boolean;
    arrowColor?: wmw.ColorOptions;
    inline?: boolean;
}

const UnstyledLink: React.FC<GatsbyLinkProps<any> & AdditionalLinkProps> = ({
    children,
    to,
    highlightColor,
    underlineColor,
    reverseColor,
    arrowColor,
    inline,
    ...other
}) => {
    // Tailor the following test to your environment.
    // This example assumes that any internal link (intended for Gatsby)
    // will start with exactly one slash, and that anything else is external.
    const internal = /^\/(?!\/)/.test(to);

    // Use Gatsby Link for internal links, and <a> for others
    if (internal) {
        return (
            <GatsbyLink
                to={to}
                className={other.className}
                aria-label={other['aria-label']}
            >
                {children}
            </GatsbyLink>
        );
    }

    return (
        <a href={to} {...other}>
            {children}
        </a>
    );
};

export const BaseLinkStyles = css<AdditionalLinkProps>`
    ${({ theme, highlightColor }) => css`
        text-decoration: none;
        color: ${theme.colors.deepBlue.color};

        position: relative;
        overflow: hidden;
        display: inline-flex;
        z-index: 1;
        white-space: nowrap;

        &:before,
        &:after {
            content: '';
            position: absolute;
            bottom: 0;
            left: 0;
            right: 0;
            height: 2px;
            background-color: ${theme.colors.deepBlue.color};
            z-index: -2;
        }

        &:after {
            background-color: ${theme.colors[highlightColor || 'electricBlue']
                .color};
            transform: translateX(-200%);
            transition: all 600ms cubic-bezier(0.31, 0.81, 0.57, 1);
        }

        &:hover:after {
            transform: translateX(0);
        }
    `};
`;

export const BaseAnchorStyles = css<AdditionalLinkProps>`
    ${({ theme, highlightColor }) => css`
        text-decoration: underline;
        color: ${theme.colors.deepBlue.color};
        text-decoration-color: ${theme.colors.deepBlue.color};
        transition: text-decoration-color 200ms;

        :hover {
            text-decoration-color: ${theme.colors[
                highlightColor || 'electricBlue'
            ].color};
        }
    `}
`;

export const Link = styled(UnstyledLink)`
    ${BaseLinkStyles}
`;

export const StylelessLink = styled(UnstyledLink)`
    color: ${({ theme }) => theme.colors.deepBlue.color};
    text-decoration: none;
`;
