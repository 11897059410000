module.exports = [{
      plugin: require('../node_modules/gatsby-plugin-mdx/gatsby-browser.js'),
      options: {"plugins":[],"extensions":[".mdx"],"gatsbyRemarkPlugins":[{"resolve":"gatsby-remark-images","options":{"maxWidth":1400}},{"resolve":"gatsby-remark-autolink-headers","options":{"icon":false}},"gatsby-remark-smartypants","gatsby-remark-copy-linked-files"]},
    },{
      plugin: require('../node_modules/gatsby-remark-autolink-headers/gatsby-browser.js'),
      options: {"plugins":[],"icon":false},
    },{
      plugin: require('../node_modules/gatsby-plugin-canonical-urls/gatsby-browser.js'),
      options: {"plugins":[],"siteUrl":"https://wemakewaves.digital"},
    },{
      plugin: require('../node_modules/gatsby-plugin-manifest/gatsby-browser.js'),
      options: {"plugins":[],"name":"We Make Waves","short_name":"WMW","start_url":"/","icon":"src/images/icon-no-bg.png","include_favicon":true,"background_color":"#003D4C","theme_color":"#003D4C","display":"minimal-ui","cache_busting_mode":"query","legacy":true,"theme_color_in_head":true,"cacheDigest":"654e71e10e8e35de0487dd335f1df007"},
    },{
      plugin: require('../node_modules/gatsby-plugin-offline/gatsby-browser.js'),
      options: {"plugins":[]},
    }]
