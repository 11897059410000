import styled, { css } from '../../../styled-components';
import { media, pixelToRem } from '../../../utilities';
import quotemarkSvg from '../../../images/quotemark.svg';

export const BlockquoteStyles = css`
    font-style: italic;
    font-size: ${({ theme }) => theme.fontNew.primary.size.level5.size};
    margin: ${pixelToRem(32)} 0;
    padding-left: ${pixelToRem(60)};
    position: relative;

    &:before {
        content: '';
        position: absolute;
        top: ${pixelToRem(7)};
        left: 0;
        width: ${pixelToRem(40)};
        height: ${pixelToRem(60)};
        background: url(${quotemarkSvg}) no-repeat;
        background-size: contain;
    }

    ${media.m`
        padding-left: ${pixelToRem(80)};

        &:before {
            width: ${pixelToRem(60)};
            height: ${pixelToRem(90)};
        }
    `}

    ${media.xl`
        font-size: ${({ theme }) => theme.fontNew.primary.size.level5.size};
    `};

    cite {
        display: block;
        text-align: right;
        font-size: ${({ theme }) => theme.fontNew.primary.size.level7.size};

        ${media.xl`
            margin-top: -1.5rem;
            font-size: ${({ theme }) => theme.fontNew.primary.size.level5.size};
        `};

        span {
            display: block;

            &:first-child {
                font-weight: 500;
            }
        }
    }
`;

export const Blockquote = styled.blockquote`
    ${BlockquoteStyles}
`;
