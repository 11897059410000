import styled from '../../../styled-components';
import { media } from '../../../utilities';

export const QuestionSubHeader = styled.p`
    font-size: ${({ theme }) => theme.fontNew.primary.size.level4.size};
    line-height: ${({ theme }) => theme.fontNew.primary.size.level4.lineHeight};
    margin-bottom: ${({ theme }) => theme.fontNew.primary.size.level4.size};

    ${media.xl`
            font-size: ${({ theme }) => theme.fontNew.primary.size.level3.size};
            line-height: ${({ theme }) =>
                theme.fontNew.primary.size.level3.lineHeight};
            margin-bottom: ${({ theme }) =>
                theme.fontNew.primary.size.level3.size};
        `}
`;
