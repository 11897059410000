import { pixelToRem } from './utilities/pixelToRem';

export type WMWTheme = typeof theme;

/* tslint:disable:object-literal-sort-keys */
const theme = {
    colors: {
        deepBlue: { color: '#003D4C', contrast: '#FFFFFF' },
        electricBlue: { color: '#00EAE9' },
        white: { color: '#FFFFFF' },
        green: { color: '#43FF45' },
        pink: { color: '#FF63C1' },
        orange: { color: '#FFB706' }
    },
    font: {
        sizing: {
            primary: {
                level1: {
                    mobile: pixelToRem(34),
                    desktop: pixelToRem(42)
                },
                level2: { mobile: pixelToRem(28), desktop: pixelToRem(36) },
                level3: { mobile: pixelToRem(24), desktop: pixelToRem(30) },
                level4: {
                    mobile: pixelToRem(20),
                    desktop: pixelToRem(24)
                },
                level5: { mobile: pixelToRem(16), desktop: pixelToRem(20) },
                level6: { mobile: pixelToRem(14), desktop: pixelToRem(14) }
            },
            secondary: {
                level1: {
                    mobile: pixelToRem(50),
                    desktop: pixelToRem(62)
                },
                level2: { mobile: pixelToRem(40), desktop: pixelToRem(50) },
                level3: { mobile: pixelToRem(32), desktop: pixelToRem(40) },
                level4: {
                    mobile: pixelToRem(20),
                    desktop: pixelToRem(30)
                },
                level5: {
                    mobile: pixelToRem(16),
                    desktop: pixelToRem(26)
                }
            }
        },
        type: {
            primary: '"akzidenz-grotesk", sans-serif',
            secondary: '"Guthen Bloots", serif'
        }
    },
    fontNew: {
        primary: {
            type: '"akzidenz-grotesk", sans-serif',
            size: {
                level1: {
                    size: '4.2rem',
                    lineHeight: '1.2'
                },
                level2: {
                    size: '3.6rem',
                    lineHeight: '1.4'
                },
                level3: {
                    size: '3rem',
                    lineHeight: '1.4'
                },
                level4: {
                    size: '2.4rem',
                    lineHeight: '1.4'
                },
                level5: {
                    size: '2rem',
                    lineHeight: '1.6'
                },
                level6: {
                    size: '1.6rem',
                    lineHeight: '1.6'
                },
                level7: {
                    size: '1.4rem',
                    lineHeight: '1.6'
                }
            }
        },
        secondary: {
            type: '"Guthen Bloots", sans-serif',
            size: {
                level1: {
                    size: '6.2rem',
                    lineHeight: '1.2'
                },
                level2: {
                    size: '5rem',
                    lineHeight: '1.2'
                },
                level3: {
                    size: '4rem',
                    lineHeight: '1.2'
                },
                level4: {
                    size: '3.2rem',
                    lineHeight: '1.2'
                },
                level5: {
                    size: '2.4rem',
                    lineHeight: '1.2'
                }
            }
        }
    },
    spacing: {
        xs: '1rem',
        s: '1.5rem',
        m: '2rem',
        l: '3rem',
        xl: '6rem',
        xxl: '9rem'
    }
};

export default theme;
