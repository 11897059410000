import React from 'react';
import styled, { css } from '../../styled-components';
import { media, pixelToRem } from '../../utilities';
import quotemarkSvg from '../../images/quotemark-full.svg';

interface ITestimonialProps extends wmw.IBaseStyledComponentProps {
    quote: string;
    by: string;
    title: string;
    color?: wmw.ColorOptions;
    image?: string;
    imageAlt?: string;
}

const Quote = styled.p`
    position: relative;
    padding-left: 7rem;
    margin-bottom: 3rem;

    &:before {
        content: '';
        position: absolute;
        top: ${pixelToRem(7)};
        left: 0;
        width: 5.5rem;
        height: 5.5rem;
        background: url(${quotemarkSvg}) no-repeat;
        background-size: 100%;

        ${media.xl`
            width: 8rem;
            height: 8rem;
        `}
    }

    ${media.l`
        padding-left: 2rem;
        padding-right: 3rem;

        &:before {
            left: -${pixelToRem(60)};
        }
    `}

    ${media.xl`
      padding-left: 5rem;
    `}
`;

const Author = styled.cite`
    display: block;
    text-align: right;

    font-size: ${({ theme }) => theme.fontNew.primary.size.level7.size};
    line-height: ${({ theme }) => theme.fontNew.primary.size.level7.lineHeight};

    span {
        display: block;
        width: 100%;
        font-weight: 400;

        &:first-child {
            font-weight: 500;
        }
    }
`;

const Image = styled.img`
    width: 40%;

    ${media.m`
        width: 25%;
    `}
`;

const ImageAuthor = styled.div`
    display: flex;
    flex-direction: row-reverse;
    align-items: flex-end;
`;

const UnstyledTestimonial: React.SFC<ITestimonialProps> = ({
    quote,
    by,
    title,
    image,
    imageAlt,
    className
}) => (
    <div className={className} data-testid="testimonial">
        <Quote>{quote}</Quote>

        <ImageAuthor>
            {image && <Image src={image} alt={imageAlt} />}

            <Author>
                <span>{by}</span>
                <span>{title}</span>
            </Author>
        </ImageAuthor>
    </div>
);

export default styled(UnstyledTestimonial)`
    position: relative;
    padding: 3rem;
    font-weight: 500;
    font-style: italic;
    font-size: ${({ theme }) => theme.fontNew.primary.size.level5.size};
    line-height: ${({ theme }) => theme.fontNew.primary.size.level5.lineHeight};

    width: 100%;
    max-width: 86rem;
    margin: 0 auto;

    ${media.xl`
        font-size: ${({ theme }) => theme.fontNew.primary.size.level4.size};
        line-height: ${({ theme }) =>
            theme.fontNew.primary.size.level4.lineHeight};
    `}

    &:before {
        content: '';
        position: absolute;
        top: 0;
        left: -9999px;
        right: 0;
        height: calc(100% - 85px);
        background-color: ${({ theme, color }) =>
            theme.colors[color || 'orange'].color};
        z-index: -1;

        ${media.m`
            right: 3rem;
        `}
    }

    ${({ image }) =>
        !!image &&
        css`
            ${Author} {
                margin-right: 1rem;
            }

            ${media.l`
                ${ImageAuthor} {
                    margin-top: -16rem;
                    margin-right: -3rem;
                }

                ${Quote} {
                    max-width: 70%;
                    padding-right: 0;
                }
            `}
        `}
`;
